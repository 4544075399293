.date-picker, .custom-input {
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: #426b9d;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  width: 100%;
  padding-left: var(--chakra-space-4);
}
.input-label {
  color: #426b9d;
  font-size: var(--chakra-fontSizes-lg);
}

.input-label-error {
  color: var(--chakra-colors-main-error);
}

.input-error-msg {
  visibility: hidden;
  color: var(--chakra-colors-main-error);
  margin-bottom: 2px;
  font-size: 11px;
}

.input-error {
  border-color: var(--chakra-colors-red-400);
}

.input-change {
background-color:  #426b9d1f;
}


.password-icon-container {
  position: absolute;
  bottom: 26px;
  right: 10px;
  color: var(--chakra-colors-main-primary);
  cursor: pointer;
}

.rmdp-container {
  width: 100%;
}
.date-picker-grey-disabled {
  align-items: center;
  background-color:hsl(0, 0%, 95%);
  border-color: #426b9d;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  width: 100%;
  padding-left: var(--chakra-space-4);
}
.date-picker-disabled {
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
  color: darkgrey;
}